import { MarketLocation } from '../../declarations/exclusives/market';
import { getId } from './getId';

export interface NeighborhoodInfo {
  label: string;
  id: string;
  population: number;
  uuid: string;
}

// To update this, reference the Snowflake query mentioned here:
// https://opendoor.atlassian.net/wiki/spaces/ENG/pages/2057437347/New+Market+Launch+ENG+Tasks#Cosmos

export const austinNeighborhoods: NeighborhoodInfo[] = [
  {
    id: 'Austin',
    label: 'Austin',
    population: 1182319,
    uuid: 'd9d12396-2daa-4f0e-a5b9-82ff79f81c11',
  },
  {
    id: 'Round Rock',
    label: 'Round Rock',
    population: 189633,
    uuid: '7574e649-da58-43ed-9ef9-b8b0b16e4832',
  },
  {
    id: 'Georgetown',
    label: 'Georgetown',
    population: 100914,
    uuid: '0e49506f-2fd5-4edc-ae1c-6551e3ed3e16',
  },
  {
    id: 'Pflugerville',
    label: 'Pflugerville',
    population: 91788,
    uuid: '8f540b68-e0a6-449e-9f4f-57fa6806f218',
  },
  {
    id: 'San Marcos',
    label: 'San Marcos',
    population: 89692,
    uuid: '764748aa-9352-4f7f-8086-641eb3e22f90',
  },
  {
    id: 'Cedar Park',
    label: 'Cedar Park',
    population: 82695,
    uuid: 'dcb5ccda-0f89-4113-bab6-55f6c3a7474e',
  },
  {
    id: 'Leander',
    label: 'Leander',
    population: 82073,
    uuid: '2279548b-37d0-4eb0-91a7-b663cfb0d082',
  },
  { id: 'Kyle', label: 'Kyle', population: 63008, uuid: 'd58bb6c0-d881-4b7b-92c8-f9a1bb1b9e87' },
  { id: 'Buda', label: 'Buda', population: 33919, uuid: '778afe81-b04d-49dc-84f4-1a20357d65d4' },
  { id: 'Hutto', label: 'Hutto', population: 32148, uuid: '1540aea7-9c99-4885-aa7d-a13393686b95' },
  {
    id: 'Bastrop',
    label: 'Bastrop',
    population: 26934,
    uuid: '200e3da1-d907-4cf2-808e-bd3caf5df08c',
  },
  {
    id: 'Taylor',
    label: 'Taylor',
    population: 25193,
    uuid: '86842495-e1ec-4575-be56-44332231b803',
  },
  {
    id: 'Del Valle',
    label: 'Del Valle',
    population: 24376,
    uuid: 'cf5e3899-09a2-4f4b-a81a-7fffb03e86b3',
  },
  { id: 'Manor', label: 'Manor', population: 24129, uuid: '2bbd1422-1743-406a-99c8-898570f7207d' },
  { id: 'Elgin', label: 'Elgin', population: 23638, uuid: '6741ca6d-7015-4d70-8b1b-04cac97ee913' },
  {
    id: 'Lockhart',
    label: 'Lockhart',
    population: 22259,
    uuid: '801069a8-5311-4e24-929f-7d255b4f2a8d',
  },
  {
    id: 'Marble Falls',
    label: 'Marble Falls',
    population: 21391,
    uuid: '4d11ec5c-1bff-4421-8b26-077c93061dc2',
  },
  {
    id: 'Dripping Springs',
    label: 'Dripping Springs',
    population: 20766,
    uuid: 'c49513c0-c7bd-4280-91dd-a36ec6222851',
  },
  {
    id: 'Wimberley',
    label: 'Wimberley',
    population: 19443,
    uuid: '47957cbe-05ff-4b61-9b4b-107f447a86d1',
  },
  {
    id: 'Liberty Hill',
    label: 'Liberty Hill',
    population: 13122,
    uuid: '25719fb8-82ef-444c-a7f7-6df79f5d29b0',
  },
  {
    id: 'Cedar Creek',
    label: 'Cedar Creek',
    population: 12238,
    uuid: '7e9ba96f-1123-41ea-a0e3-61f968c5f34b',
  },
  {
    id: 'Spicewood',
    label: 'Spicewood',
    population: 10607,
    uuid: '930f0ad4-d371-4576-bf4b-e85139510b55',
  },
  {
    id: 'Smithville',
    label: 'Smithville',
    population: 10060,
    uuid: 'f8a90525-8da5-473b-ac6b-0fb9f5020c30',
  },
  {
    id: 'Manchaca',
    label: 'Manchaca',
    population: 7371,
    uuid: '4f99e088-d1b7-4205-ba1b-1729612059b3',
  },
  { id: 'Blanco', label: 'Blanco', population: 6796, uuid: '381f15fe-8a2f-457b-a1fd-e8e643bc0012' },
  {
    id: 'Jarrell',
    label: 'Jarrell',
    population: 5709,
    uuid: '3d6c96b3-fe43-4522-9f40-c15757e97dde',
  },
  {
    id: 'Bertram',
    label: 'Bertram',
    population: 5443,
    uuid: 'e70f41c3-bf64-42da-9ec1-449e8e060f30',
  },
  { id: 'Paige', label: 'Paige', population: 4944, uuid: 'bac146d6-04ad-430c-b74a-23c204a61185' },
];

export const houstonNeighborhoods: NeighborhoodInfo[] = [
  {
    id: 'Houston',
    label: 'Houston',
    population: 3356182,
    uuid: '6a582dfc-6e03-4a51-9f80-a8830e9047ff',
  },
  {
    id: 'Spring',
    label: 'Spring',
    population: 381097,
    uuid: '286c1db5-fd77-4b46-8971-e221e5c2aeee',
  },
  { id: 'Katy', label: 'Katy', population: 305231, uuid: '1fb525a6-1984-4d34-973b-6217f08c08cd' },
  {
    id: 'Sugar Land',
    label: 'Sugar Land',
    population: 208838,
    uuid: '792c8207-63b9-49e6-91b4-1207c204cbf4',
  },
  {
    id: 'Conroe',
    label: 'Conroe',
    population: 177239,
    uuid: 'a9a48dd6-1b24-4025-aa49-e9ee8f89e5ce',
  },
  {
    id: 'Pasadena',
    label: 'Pasadena',
    population: 169110,
    uuid: '3405d42b-801d-4742-a772-3d0cd79c2347',
  },
  {
    id: 'Humble',
    label: 'Humble',
    population: 150785,
    uuid: 'd4a9bfda-a736-40cc-ac13-41588e77a20f',
  },
  {
    id: 'Cypress',
    label: 'Cypress',
    population: 145058,
    uuid: 'a0308b95-ef99-4d16-88d8-6b7a143413e9',
  },
  {
    id: 'Richmond',
    label: 'Richmond',
    population: 138477,
    uuid: 'e6fb71c6-cec7-4c13-bb6f-ada9a0134d16',
  },
  {
    id: 'Pearland',
    label: 'Pearland',
    population: 132614,
    uuid: '2500cce2-c44e-4802-b0a5-673b655d1ae4',
  },
  {
    id: 'Missouri City',
    label: 'Missouri City',
    population: 123267,
    uuid: 'f08709bf-6b69-422e-baed-a65351fc2ce4',
  },
  {
    id: 'Baytown',
    label: 'Baytown',
    population: 118719,
    uuid: 'd0304ad4-d823-4a0c-94f4-11e228bf3909',
  },
  {
    id: 'League City',
    label: 'League City',
    population: 84438,
    uuid: '3eca47e3-6426-40cd-99a5-8d29f703426d',
  },
  {
    id: 'Magnolia',
    label: 'Magnolia',
    population: 80588,
    uuid: '7a2e0bbd-e6ce-4ee7-9fd4-0963df4b481d',
  },
  {
    id: 'Tomball',
    label: 'Tomball',
    population: 74355,
    uuid: '91c98d72-f0d5-499a-898d-37bbb12780a5',
  },
  {
    id: 'Kingwood',
    label: 'Kingwood',
    population: 68008,
    uuid: 'e6a93513-f20c-473e-83df-8e3555868eed',
  },
  {
    id: 'Friendswood',
    label: 'Friendswood',
    population: 56235,
    uuid: 'ec44d867-1e8f-45f6-9072-4145d0276977',
  },
  { id: 'Alvin', label: 'Alvin', population: 55130, uuid: '193fe071-c04a-491b-acf3-222ab83485f6' },
  {
    id: 'Galveston',
    label: 'Galveston',
    population: 54697,
    uuid: '0f102c07-8047-4146-90f2-c9e3d3a011ab',
  },
  {
    id: 'Montgomery',
    label: 'Montgomery',
    population: 53562,
    uuid: '929d6dbf-952f-47a3-9f29-3f7df0d2a611',
  },
  {
    id: 'Dickinson',
    label: 'Dickinson',
    population: 49243,
    uuid: '3dc3f176-a182-4526-8406-8e95c5a53207',
  },
  {
    id: 'Rosenberg',
    label: 'Rosenberg',
    population: 48918,
    uuid: 'f2084d53-b79f-466b-84ce-c10178e1b0e5',
  },
  {
    id: 'Texas City',
    label: 'Texas City',
    population: 47222,
    uuid: 'bd4566a4-c0ab-4d3e-8d31-6d1d1a48dff8',
  },
  {
    id: 'Stafford',
    label: 'Stafford',
    population: 46419,
    uuid: 'dc8334a2-b703-4e50-a690-2db675cef406',
  },
  {
    id: 'Cleveland',
    label: 'Cleveland',
    population: 45986,
    uuid: '9c5104cf-82de-4bc2-9450-661c90b4762b',
  },
  {
    id: 'Angleton',
    label: 'Angleton',
    population: 39781,
    uuid: 'c3d6e6be-78d0-4a64-92bc-92a946734d9a',
  },
  {
    id: 'La Porte',
    label: 'La Porte',
    population: 39607,
    uuid: 'f042c002-f71c-426c-aaed-eb96b00a6ee4',
  },
  {
    id: 'Willis',
    label: 'Willis',
    population: 39478,
    uuid: 'ee856f4e-fd9e-461e-96b3-e5c89c055f4a',
  },
  {
    id: 'Porter',
    label: 'Porter',
    population: 38835,
    uuid: '78802d85-56ad-4850-9bed-d47a0d50aa36',
  },
  {
    id: 'Dayton',
    label: 'Dayton',
    population: 37449,
    uuid: '5c31e05a-7301-4765-9f6d-2529142b17a9',
  },
  {
    id: 'Channelview',
    label: 'Channelview',
    population: 35263,
    uuid: '66957a60-d6d5-4bb2-a1a1-cbce86e8c167',
  },
  {
    id: 'Rosharon',
    label: 'Rosharon',
    population: 35129,
    uuid: 'b5003d39-0188-4e08-bb0e-ea1aa72f870c',
  },
  {
    id: 'Deer Park',
    label: 'Deer Park',
    population: 34972,
    uuid: '7f4621ff-d4c2-4ff7-99a7-572941221ffc',
  },
  {
    id: 'Webster',
    label: 'Webster',
    population: 28955,
    uuid: 'ed1bbe49-8189-4391-b1fd-dae24b49e0cc',
  },
  {
    id: 'New Caney',
    label: 'New Caney',
    population: 28208,
    uuid: '3c0149a0-ab56-4403-a488-0ab000ddf8ab',
  },
  {
    id: 'Crosby',
    label: 'Crosby',
    population: 28192,
    uuid: 'fd6ef269-c446-4507-bc1b-39750bbe50bb',
  },
  {
    id: 'Fresno',
    label: 'Fresno',
    population: 25542,
    uuid: 'be1b4669-9439-455f-a48d-b34a064d4b94',
  },
  {
    id: 'Seabrook',
    label: 'Seabrook',
    population: 24227,
    uuid: '76af9040-c4dd-4bc8-a99c-69f6c7690e0b',
  },
  {
    id: 'Santa Fe',
    label: 'Santa Fe',
    population: 22404,
    uuid: '8d4c9a0b-ed88-49c2-bac5-792d8c326672',
  },
  {
    id: 'Manvel',
    label: 'Manvel',
    population: 22026,
    uuid: 'f34c5bc6-011b-4862-9d78-c30ec372d495',
  },
  {
    id: 'Hempstead',
    label: 'Hempstead',
    population: 20277,
    uuid: '0e4952f1-3191-4211-8f3d-7a5ded3d6bb7',
  },
  {
    id: 'South Houston',
    label: 'South Houston',
    population: 19209,
    uuid: '0e69e657-eb21-4458-8644-0ba2a76aec8f',
  },
  {
    id: 'Liberty',
    label: 'Liberty',
    population: 19056,
    uuid: '8c387274-24e8-4e48-a7a6-d5fa999cef23',
  },
  {
    id: 'La Marque',
    label: 'La Marque',
    population: 17808,
    uuid: '0f16a05e-193d-4d3f-b102-5e7c230add85',
  },
  {
    id: 'Hockley',
    label: 'Hockley',
    population: 16014,
    uuid: '75b71896-4edb-4bf0-885c-b1246ae42e3a',
  },
  {
    id: 'Splendora',
    label: 'Splendora',
    population: 14611,
    uuid: '61205133-bc51-4788-acfd-f1cdabe92914',
  },
  {
    id: 'Brookshire',
    label: 'Brookshire',
    population: 14585,
    uuid: 'a84afaa8-7817-4499-aec1-e66376a9de7a',
  },
  { id: 'Sealy', label: 'Sealy', population: 14254, uuid: '9d082233-903b-4f8f-a7d5-2f231b080998' },
  {
    id: 'Huffman',
    label: 'Huffman',
    population: 13854,
    uuid: '53fd6f58-5fa8-4ea7-b2e6-0a7dd93ce9f5',
  },
  {
    id: 'Highlands',
    label: 'Highlands',
    population: 13003,
    uuid: 'd401185e-da26-4c2e-8bf8-d249a7bf9073',
  },
  {
    id: 'Hitchcock',
    label: 'Hitchcock',
    population: 10746,
    uuid: 'e65fce61-cbea-4520-9bbe-840ca530e064',
  },
  {
    id: 'Galena Park',
    label: 'Galena Park',
    population: 8558,
    uuid: 'd0deb272-f284-49b8-a106-4b1ae8372b57',
  },
  {
    id: 'West Columbia',
    label: 'West Columbia',
    population: 8496,
    uuid: '830e6e09-22bb-42aa-baf9-20202be372c0',
  },
  {
    id: 'Bacliff',
    label: 'Bacliff',
    population: 8450,
    uuid: '5be1fb1e-9d6f-4bc6-afdc-001ac5fb6670',
  },
  { id: 'Kemah', label: 'Kemah', population: 8335, uuid: '8cb23ad6-b968-4cba-8cdb-e1d0eba1ad49' },
  {
    id: 'Pinehurst',
    label: 'Pinehurst',
    population: 6303,
    uuid: 'efbd715f-f24e-4c4e-98a2-e1d6f31b9b02',
  },
  {
    id: 'Fulshear',
    label: 'Fulshear',
    population: 6101,
    uuid: '8a287115-7afc-4d3f-b666-5eda26b319aa',
  },
  {
    id: 'Danbury',
    label: 'Danbury',
    population: 2272,
    uuid: 'fb01880b-fd86-4859-8121-5ff97dd6269d',
  },
];

export const dallasNeighborhoods: NeighborhoodInfo[] = [
  {
    id: 'Dallas',
    label: 'Dallas',
    population: 1411639,
    uuid: 'c1001708-8826-46d3-a185-25ccd3d9b5f2',
  },
  {
    id: 'Fort Worth',
    label: 'Fort Worth',
    population: 951932,
    uuid: 'ea246213-552e-43cc-a122-8ff10b46a4bb',
  },
  {
    id: 'Arlington',
    label: 'Arlington',
    population: 428711,
    uuid: 'd3f7e439-cb54-45be-acda-bfcf933efe8e',
  },
  { id: 'Plano', label: 'Plano', population: 371188, uuid: 'a19cd8ba-52d8-4c9c-9c12-1386c4ef269d' },
  {
    id: 'Garland',
    label: 'Garland',
    population: 253427,
    uuid: 'da7f21aa-82a4-40fd-9fb2-1b60455df423',
  },
  {
    id: 'Irving',
    label: 'Irving',
    population: 236653,
    uuid: '08a354b2-7fa0-42a3-b681-ce8493400c66',
  },
  {
    id: 'McKinney',
    label: 'McKinney',
    population: 195369,
    uuid: '42b6b446-39bd-43ef-994f-bfd9280d7c94',
  },
  {
    id: 'Grand Prairie',
    label: 'Grand Prairie',
    population: 194211,
    uuid: 'cbfb153c-cc2b-4172-95ec-1f191dff2414',
  },
  {
    id: 'Denton',
    label: 'Denton',
    population: 186581,
    uuid: '3c6650ce-d38a-45b1-bec8-c989f010a77d',
  },
  {
    id: 'Frisco',
    label: 'Frisco',
    population: 160881,
    uuid: '0bac83df-e52c-41bc-924d-fc70d4328436',
  },
  {
    id: 'Mesquite',
    label: 'Mesquite',
    population: 155216,
    uuid: '816a3c05-9ca5-4016-a7ba-af8a4f3483b1',
  },
  {
    id: 'Carrollton',
    label: 'Carrollton',
    population: 150788,
    uuid: '2a6fdd6c-d9b6-44ca-90b1-53fc5ed12bd3',
  },
  {
    id: 'Lewisville',
    label: 'Lewisville',
    population: 146302,
    uuid: '006486a8-9593-412f-948d-108c482b3071',
  },
  { id: 'Allen', label: 'Allen', population: 128454, uuid: '5234bebf-d6ba-4e72-b359-effa0e465c3d' },
  {
    id: 'Richardson',
    label: 'Richardson',
    population: 115956,
    uuid: 'a09ef7d3-34ef-438e-9be7-cccaffcb1d7b',
  },
  {
    id: 'Keller',
    label: 'Keller',
    population: 107108,
    uuid: 'f69492b4-edaf-490a-b3b4-8662f1da0676',
  },
  {
    id: 'Flower Mound',
    label: 'Flower Mound',
    population: 87784,
    uuid: '3883030b-06c9-42eb-b30d-f794950ae43e',
  },
  {
    id: 'Weatherford',
    label: 'Weatherford',
    population: 81978,
    uuid: '1d04c3b2-a0ec-49b0-b46e-9fcba0a75b84',
  },
  {
    id: 'North Richland Hills',
    label: 'North Richland Hills',
    population: 73309,
    uuid: '08f599d7-444c-4699-a8bf-e1921f5098ec',
  },
  {
    id: 'Rockwall',
    label: 'Rockwall',
    population: 73227,
    uuid: '26c1b494-ad2c-4c2c-b8ed-45f0612d9d95',
  },
  {
    id: 'Mansfield',
    label: 'Mansfield',
    population: 72371,
    uuid: 'df987d2c-893f-42e9-8d25-67837fc7a082',
  },
  {
    id: 'Euless',
    label: 'Euless',
    population: 71562,
    uuid: '9adb0200-428c-4c72-bf6a-eea052de5975',
  },
  {
    id: 'Burleson',
    label: 'Burleson',
    population: 69976,
    uuid: '2829c83f-e5f2-463e-9433-978ca42bbd9e',
  },
  { id: 'Wylie', label: 'Wylie', population: 67874, uuid: 'abadaf85-4a7a-4b0e-a15d-d5542e243c90' },
  {
    id: 'The Colony',
    label: 'The Colony',
    population: 64333,
    uuid: 'b9b10bb1-310c-4514-b410-a849e76edea3',
  },
  {
    id: 'Rowlett',
    label: 'Rowlett',
    population: 61051,
    uuid: '5b662a00-a8fd-44dc-80d4-e3076fb7f641',
  },
  {
    id: 'Desoto',
    label: 'Desoto',
    population: 58792,
    uuid: 'dab9609b-02fa-4d66-aec9-b4c1f979f7a4',
  },
  {
    id: 'Waxahachie',
    label: 'Waxahachie',
    population: 58037,
    uuid: '9dd30c34-7424-4a9d-a541-174d7d8a91ca',
  },
  {
    id: 'Cleburne',
    label: 'Cleburne',
    population: 56878,
    uuid: '345d3192-8276-436c-83a8-b8978a822951',
  },
  {
    id: 'Cedar Hill',
    label: 'Cedar Hill',
    population: 53842,
    uuid: '94d11d4e-1bba-456c-bb48-f3627ddb3a61',
  },
  {
    id: 'Bedford',
    label: 'Bedford',
    population: 53824,
    uuid: 'fde1d907-472a-444f-8d3d-a254db60b569',
  },
  {
    id: 'Granbury',
    label: 'Granbury',
    population: 52349,
    uuid: '3445c073-f690-4835-b3a9-6ea76b5caa0d',
  },
  {
    id: 'Grapevine',
    label: 'Grapevine',
    population: 51704,
    uuid: '7055d04f-4465-4b29-aaf0-e953bc5c127f',
  },
  {
    id: 'Little Elm',
    label: 'Little Elm',
    population: 48582,
    uuid: '537d615b-fb2c-4a10-818a-a6f8b5683a46',
  },
  {
    id: 'Coppell',
    label: 'Coppell',
    population: 45743,
    uuid: 'a4361d1f-0356-4cd3-b9b5-fcfcc14fded8',
  },
  {
    id: 'Lancaster',
    label: 'Lancaster',
    population: 45041,
    uuid: '8b0cfcec-704e-4587-bcf8-32c73f510c31',
  },
  { id: 'Hurst', label: 'Hurst', population: 43835, uuid: '5786e01b-9c66-4329-8859-bf475c708f88' },
  {
    id: 'Duncanville',
    label: 'Duncanville',
    population: 42013,
    uuid: 'c4dde437-0059-4217-9ab3-f4ecfad49293',
  },
  {
    id: 'Forney',
    label: 'Forney',
    population: 41943,
    uuid: 'b0d3a8df-54f7-4f8d-b105-0b07ca33d43b',
  },
  {
    id: 'Red Oak',
    label: 'Red Oak',
    population: 41092,
    uuid: 'ef363633-576a-4bef-ba9b-0cde76186ae9',
  },
  {
    id: 'Greenville',
    label: 'Greenville',
    population: 38924,
    uuid: 'ba63cf43-112b-490e-8f32-45511f8dbeb1',
  },
  {
    id: 'Roanoke',
    label: 'Roanoke',
    population: 36645,
    uuid: '1b3c5450-907f-4d89-8e21-606c18bccc50',
  },
  {
    id: 'Midlothian',
    label: 'Midlothian',
    population: 36423,
    uuid: '12b7c69d-c80b-467a-b3ce-a77470c58b8d',
  },
  {
    id: 'Haltom City',
    label: 'Haltom City',
    population: 36378,
    uuid: '6052601e-42fe-4fe7-8714-82474d7a1f09',
  },
  {
    id: 'Southlake',
    label: 'Southlake',
    population: 34604,
    uuid: 'c8a56692-a109-475c-95ee-e1ed39f85c68',
  },
  { id: 'Ennis', label: 'Ennis', population: 34409, uuid: '1b0b750d-370f-4c38-a36c-64c6a6946a5f' },
  {
    id: 'Royse City',
    label: 'Royse City',
    population: 31859,
    uuid: '8b278fe2-4db2-4de0-8d1c-961af43d73b1',
  },
  { id: 'Azle', label: 'Azle', population: 31602, uuid: '0f0f6c56-e23d-4222-a1f4-f0f4821415ab' },
  {
    id: 'Aubrey',
    label: 'Aubrey',
    population: 30745,
    uuid: '96e04b55-5b62-4db6-88a4-99129f0707ac',
  },
  {
    id: 'Crowley',
    label: 'Crowley',
    population: 27247,
    uuid: 'e8141e30-54bf-406b-b9f9-164748367407',
  },
  {
    id: 'Colleyville',
    label: 'Colleyville',
    population: 27179,
    uuid: 'e8be5b97-9446-4ef0-bc44-f5a256fcdabb',
  },
  {
    id: 'Argyle',
    label: 'Argyle',
    population: 26906,
    uuid: 'cb9710b7-bb1a-4ca5-b041-b088fab8324a',
  },
  {
    id: 'Balch Springs',
    label: 'Balch Springs',
    population: 25949,
    uuid: 'c14db7a7-6946-4536-a64c-783860f3b59d',
  },
  {
    id: 'Alvarado',
    label: 'Alvarado',
    population: 25862,
    uuid: '63571ec2-c2c6-432b-86ee-aec6a89ca1e8',
  },
  {
    id: 'Kaufman',
    label: 'Kaufman',
    population: 24809,
    uuid: '1b501f5d-01ce-4320-8d57-48f1841cf7a5',
  },
  {
    id: 'Seagoville',
    label: 'Seagoville',
    population: 22192,
    uuid: '3263d2f4-7017-4050-aae6-82c9fc455d5e',
  },
  {
    id: 'Sachse',
    label: 'Sachse',
    population: 21634,
    uuid: '1cbfebbc-b0f2-4e9f-9b58-5a237ad59718',
  },
  {
    id: 'Joshua',
    label: 'Joshua',
    population: 20568,
    uuid: '114a88f4-2cee-4502-b686-a04b9a23fc7f',
  },
  {
    id: 'Springtown',
    label: 'Springtown',
    population: 20538,
    uuid: '2b304dcc-f431-4333-bd1f-00e15160c381',
  },
  {
    id: 'Sanger',
    label: 'Sanger',
    population: 19712,
    uuid: '845ee0f7-1c16-471e-97a0-5b15ade58424',
  },
  {
    id: 'Decatur',
    label: 'Decatur',
    population: 19507,
    uuid: '0d33fc5d-c786-4ec0-98ea-8f98253633b5',
  },
  {
    id: 'Justin',
    label: 'Justin',
    population: 19194,
    uuid: 'ff68febc-3f0d-4517-ba6d-acee0bdffaf3',
  },
  {
    id: 'Princeton',
    label: 'Princeton',
    population: 18122,
    uuid: 'ed40ffef-839a-4ce4-86ef-0aa8a2b05d08',
  },
  { id: 'Aledo', label: 'Aledo', population: 18077, uuid: '24b645a6-e77e-4bde-bf55-935aaa9013ba' },
  {
    id: 'Haslet',
    label: 'Haslet',
    population: 17857,
    uuid: '3369a962-480a-48ae-a7df-f218fa7c89ff',
  },
  { id: 'Anna', label: 'Anna', population: 17064, uuid: 'a751a7a0-d2a3-4430-9c54-b05ab9d15b0c' },
  {
    id: 'Prosper',
    label: 'Prosper',
    population: 14338,
    uuid: 'a22484dd-9003-43d7-83db-6e05aca498f4',
  },
  {
    id: 'Addison',
    label: 'Addison',
    population: 13929,
    uuid: '285870cb-822f-4b37-ab32-fa7a571197c0',
  },
  {
    id: 'Lake Dallas',
    label: 'Lake Dallas',
    population: 12659,
    uuid: '26e672d3-3773-43da-b41d-9ac25d8e4dfb',
  },
  {
    id: 'Farmersville',
    label: 'Farmersville',
    population: 12506,
    uuid: '41b19ab7-a3bb-4ccc-89cd-e169cc77525f',
  },
  {
    id: 'Celina',
    label: 'Celina',
    population: 11459,
    uuid: '0629d860-cd94-46d1-9586-49ac27659830',
  },
  {
    id: 'Sunnyvale',
    label: 'Sunnyvale',
    population: 10072,
    uuid: '8b730aee-dc6c-4e40-8bb3-bc678ede1a99',
  },
  { id: 'Krum', label: 'Krum', population: 9193, uuid: '395375a7-49a7-449e-a3b1-968b22b02f04' },
  { id: 'Ferris', label: 'Ferris', population: 9139, uuid: 'd573245a-2731-4127-8752-5f4982ad032d' },
  { id: 'Rhome', label: 'Rhome', population: 9030, uuid: '23f74010-dee2-4104-a38f-3985184431d8' },
  {
    id: 'Kennedale',
    label: 'Kennedale',
    population: 8778,
    uuid: '85ff2252-bc6c-45a2-8bcf-ef51745ccd11',
  },
  {
    id: 'Crandall',
    label: 'Crandall',
    population: 8118,
    uuid: 'b5b35c62-b758-44dc-ace4-d9ef6af7747a',
  },
  {
    id: 'Van Alstyne',
    label: 'Van Alstyne',
    population: 7779,
    uuid: '1c939181-e9c0-4c5a-90e9-b9c6ea547dc2',
  },
  { id: 'Boyd', label: 'Boyd', population: 7686, uuid: '71e59f6c-7eb7-4421-9cf1-113278a74278' },
  { id: 'Venus', label: 'Venus', population: 7444, uuid: '37882ac0-4480-43b0-b3d7-5de0f1e4244c' },
  {
    id: 'Caddo Mills',
    label: 'Caddo Mills',
    population: 7319,
    uuid: '0e0b65df-17ac-41cb-a748-b13440e6f15a',
  },
  {
    id: 'Melissa',
    label: 'Melissa',
    population: 6594,
    uuid: '011008a8-d15e-4031-a0ac-d4fc4eceda0c',
  },
  {
    id: 'Paradise',
    label: 'Paradise',
    population: 6545,
    uuid: '60747e6e-76fd-4ef7-af9b-ac240bc1a98c',
  },
  { id: 'Palmer', label: 'Palmer', population: 5983, uuid: 'd792555a-bb07-4472-86ce-563caba0a5cd' },
  {
    id: 'Hutchins',
    label: 'Hutchins',
    population: 5939,
    uuid: '0df0f878-42f8-4f5e-a951-a589d408a0cc',
  },
  { id: 'Godley', label: 'Godley', population: 5067, uuid: 'f028b178-d2b4-4ddf-ba6d-5eeb591ebce8' },
  {
    id: 'Leonard',
    label: 'Leonard',
    population: 4967,
    uuid: 'cc4d9e05-5432-4a1c-a3f9-e663628ce287',
  },
  { id: 'Ponder', label: 'Ponder', population: 4770, uuid: 'de73353a-5c26-4851-aa6e-c7920bcbd8f9' },
  {
    id: 'Blue Ridge',
    label: 'Blue Ridge',
    population: 3951,
    uuid: '7773d6d7-d2ba-4f02-b74d-febc1ad6e85a',
  },
  {
    id: 'Celeste',
    label: 'Celeste',
    population: 3784,
    uuid: '5f5bcaea-998b-4e94-8ed8-ece2f82d20a5',
  },
  {
    id: 'Savannah',
    label: 'Savannah',
    population: 3283,
    uuid: 'a06c4bc9-39d6-4ba4-915f-33b1be076bf9',
  },
  { id: 'Lavon', label: 'Lavon', population: 3165, uuid: '358ff904-386e-43da-b514-59f272f0a1c4' },
  { id: 'Newark', label: 'Newark', population: 3118, uuid: '7cb5723f-7ea5-483b-885b-ff159bebf972' },
  { id: 'Keene', label: 'Keene', population: 2669, uuid: '8606a901-15c3-47d9-84e0-d03d5d91390a' },
  { id: 'Wilmer', label: 'Wilmer', population: 2296, uuid: '35884354-6a49-4cf1-af53-d41f90c5ff60' },
  {
    id: 'Cresson',
    label: 'Cresson',
    population: 2154,
    uuid: '27e84cd0-61fc-43be-b683-8bc8db2f67f3',
  },
  {
    id: 'Glenn Heights',
    label: 'Glenn Heights',
    population: 449,
    uuid: 'ad89714a-5f0f-448b-83e4-7612d363ece8',
  },
];

export const raleighNeighborhoods: NeighborhoodInfo[] = [
  {
    id: 'Raleigh',
    label: 'Raleigh',
    population: 591928,
    uuid: 'b0644ea7-72b3-4779-bf73-57405d3e1e0c',
  },
  {
    id: 'Durham',
    label: 'Durham',
    population: 309419,
    uuid: '0f93c335-3771-49e9-a1af-b635bae1647b',
  },
  {
    id: 'Cary',
    label: 'Cary',
    population: 158429,
    uuid: 'eb8fd23e-9005-4a54-8528-e20d477223fd',
  },
  {
    id: 'Chapel Hill',
    label: 'Chapel Hill',
    population: 107080,
    uuid: '0f8daee4-e610-4d1b-8146-c7dff7894ca8',
  },
  {
    id: 'Apex',
    label: 'Apex',
    population: 86382,
    uuid: '445f6862-273c-41fc-a6ee-398c63165391',
  },
  {
    id: 'Wake Forest',
    label: 'Wake Forest',
    population: 72498,
    uuid: 'f4275c2a-c0ad-4179-9595-9ee3a67c180a',
  },
  {
    id: 'Clayton',
    label: 'Clayton',
    population: 66653,
    uuid: 'ca98474a-db23-49b4-866c-16b1e043abf2',
  },
  {
    id: 'Garner',
    label: 'Garner',
    population: 57237,
    uuid: '5413ee7d-69ec-4855-b5ab-7ca9a5bf30dd',
  },
  {
    id: 'Fuquay Varina',
    label: 'Fuquay Varina',
    population: 48198,
    uuid: 'e202ba52-4cb5-4012-9cb4-49d7224abaac',
  },
  {
    id: 'Mebane',
    label: 'Mebane',
    population: 30799,
    uuid: '91bdc8b4-a8b2-4922-8e24-a73748ac5966',
  },
  {
    id: 'Holly Springs',
    label: 'Holly Springs',
    population: 29591,
    uuid: '4a5ba8f3-7b7a-470b-aef2-366d0ceb5cb2',
  },
  {
    id: 'Knightdale',
    label: 'Knightdale',
    population: 29496,
    uuid: 'ff75da1e-8d91-405d-bd05-fe49a90ee600',
  },
  {
    id: 'Zebulon',
    label: 'Zebulon',
    population: 28942,
    uuid: '62a133c9-7e22-48f3-a151-fcd62d60ce42',
  },
  {
    id: 'Louisburg',
    label: 'Louisburg',
    population: 27687,
    uuid: '6e2d9daf-1589-48c3-8970-9b862b2813d9',
  },
  {
    id: 'Morrisville',
    label: 'Morrisville',
    population: 26777,
    uuid: '6f762200-4228-435c-aed3-41d9964b8b28',
  },
  {
    id: 'Hillsborough',
    label: 'Hillsborough',
    population: 26525,
    uuid: '64847521-3ad0-4e7c-9e17-2ae333d7163c',
  },
  {
    id: 'Wendell',
    label: 'Wendell',
    population: 24941,
    uuid: '23623153-dd69-4ca9-b0af-ca9770e3aac5',
  },
  {
    id: 'Angier',
    label: 'Angier',
    population: 21151,
    uuid: 'f19e8183-75be-4160-9c48-3bab6ac63881',
  },
  {
    id: 'Willow Spring',
    label: 'Willow Spring',
    population: 19841,
    uuid: '0e0c915f-e007-4996-8b3e-151de84dd638',
  },
  {
    id: 'Benson',
    label: 'Benson',
    population: 18455,
    uuid: '8bc046ce-4dcf-4562-a463-bda2a882d398',
  },
  {
    id: 'Youngsville',
    label: 'Youngsville',
    population: 16740,
    uuid: 'eb0865cd-874d-44e9-8b2f-d142d0e2f725',
  },
  {
    id: 'Franklinton',
    label: 'Franklinton',
    population: 14913,
    uuid: '6c07982c-bff0-4d92-8c28-30277c132f00',
  },
  {
    id: 'Creedmoor',
    label: 'Creedmoor',
    population: 14313,
    uuid: 'a58687fa-2723-416b-80d0-0d13d2220e2a',
  },
  {
    id: 'Carrboro',
    label: 'Carrboro',
    population: 13247,
    uuid: 'f403825d-1fc0-4ef2-9d93-07ce9911a562',
  },
  {
    id: 'Butner',
    label: 'Butner',
    population: 7624,
    uuid: 'a4a68707-8e05-4a57-bf14-6f91d28112f6',
  },
  {
    id: 'Bahama',
    label: 'Bahama',
    population: 4692,
    uuid: '0b011136-0ceb-4a07-8da9-535b74941b53',
  },
  {
    id: 'New Hill',
    label: 'New Hill',
    population: 4072,
    uuid: '38730eaa-eeb7-4d96-9099-c46115a08727',
  },
  {
    id: 'Rolesville',
    label: 'Rolesville',
    population: 2804,
    uuid: '65a3c3cf-1842-47e8-a077-19b770f3b244',
  },
];

export const charlotteNeighborhoods: NeighborhoodInfo[] = [
  {
    id: 'Charlotte',
    label: 'Charlotte',
    population: 949246,
    uuid: '956c5c15-930b-4829-b8d0-25282d1fc32c',
  },
  {
    id: 'Concord',
    label: 'Concord',
    population: 132694,
    uuid: '53edff2e-736b-4e26-81a5-d943974d1c44',
  },
  {
    id: 'Gastonia',
    label: 'Gastonia',
    population: 111165,
    uuid: '450e30c7-bf82-4349-8355-5e23aa6112e7',
  },
  {
    id: 'Monroe',
    label: 'Monroe',
    population: 90576,
    uuid: '82de3184-58a2-4253-a144-6ef2db087ec6',
  },
  {
    id: 'Mooresville',
    label: 'Mooresville',
    population: 80318,
    uuid: '9246cb54-46cc-4e39-9291-ce17b8462422',
  },
  {
    id: 'Matthews',
    label: 'Matthews',
    population: 79577,
    uuid: '4d2fc7b6-6eb0-469d-b3e6-453276cf8714',
  },
  {
    id: 'Salisbury',
    label: 'Salisbury',
    population: 78952,
    uuid: 'f0d0e85a-117d-423d-9fc5-39093df0c51e',
  },
  {
    id: 'Statesville',
    label: 'Statesville',
    population: 78295,
    uuid: 'bf95f0b5-3838-4336-bd68-f96b9d772b38',
  },
  {
    id: 'Huntersville',
    label: 'Huntersville',
    population: 63260,
    uuid: '26463b07-39fd-4d1b-af11-28149437155c',
  },
  {
    id: 'Kannapolis',
    label: 'Kannapolis',
    population: 54784,
    uuid: '581cc1f8-ce79-4ef1-991a-005fcca0a589',
  },
  {
    id: 'Waxhaw',
    label: 'Waxhaw',
    population: 54453,
    uuid: 'b23f9471-dbc6-4f52-9535-1cddfe51a866',
  },
  {
    id: 'Lincolnton',
    label: 'Lincolnton',
    population: 41907,
    uuid: '02935139-3b8e-405c-a564-a658b4197081',
  },
  {
    id: 'Indian Trail',
    label: 'Indian Trail',
    population: 40608,
    uuid: '8e42638f-6a80-40a9-9686-928ebda7e97e',
  },
  {
    id: 'Cornelius',
    label: 'Cornelius',
    population: 29684,
    uuid: 'c02e4f75-2973-418e-a21f-b60e4b075b5f',
  },
  {
    id: 'Kings Mountain',
    label: 'Kings Mountain',
    population: 27862,
    uuid: '1616f63a-dcd4-4468-b95e-5cc56d28d2d9',
  },
  {
    id: 'Belmont',
    label: 'Belmont',
    population: 22563,
    uuid: '153e1641-9641-4da3-9761-469df76cfbd9',
  },
  {
    id: 'Mount Holly',
    label: 'Mount Holly',
    population: 20970,
    uuid: 'a5fddae6-06bf-49ae-8684-1d9ed118c1c3',
  },
  {
    id: 'Harrisburg',
    label: 'Harrisburg',
    population: 20911,
    uuid: 'e64c07f8-1bf8-4af8-b952-8bc5b9c86395',
  },
  {
    id: 'Denver',
    label: 'Denver',
    population: 19442,
    uuid: '8ba7f646-260d-41b7-9092-66ab85a33e90',
  },
  {
    id: 'Dallas',
    label: 'Dallas',
    population: 18355,
    uuid: '9a3b338d-b6e1-40e4-931d-aaa83ce1f5bf',
  },
  {
    id: 'Davidson',
    label: 'Davidson',
    population: 16786,
    uuid: '34468d2e-499f-4ec6-a6ff-ccf8da7394bd',
  },
  {
    id: 'Stanley',
    label: 'Stanley',
    population: 16051,
    uuid: '31686ee4-8a99-494c-9014-f6e15fe780a7',
  },
  {
    id: 'Pineville',
    label: 'Pineville',
    population: 15273,
    uuid: '120dad1e-088d-4bd7-bf63-60534229da3f',
  },
  {
    id: 'Cherryville',
    label: 'Cherryville',
    population: 15059,
    uuid: 'bd6afd7b-f549-43c6-95e6-f8ffda61e3d4',
  },
  {
    id: 'Bessemer City',
    label: 'Bessemer City',
    population: 14673,
    uuid: 'ecd965b7-8302-4879-8642-79596ab484a3',
  },
  {
    id: 'Marshville',
    label: 'Marshville',
    population: 13503,
    uuid: 'efc06d27-0063-4a48-bbdd-73e4ccdf61f7',
  },
  {
    id: 'Maiden',
    label: 'Maiden',
    population: 12787,
    uuid: '5c63a01c-7977-4974-9b29-48c265e3b060',
  },
  {
    id: 'Rockwell',
    label: 'Rockwell',
    population: 11278,
    uuid: 'e58ea141-e5f4-4e66-a6fc-b4540f5827ce',
  },
  {
    id: 'Vale',
    label: 'Vale',
    population: 11082,
    uuid: 'cd581e82-2c62-44ca-b285-efab3bbbb713',
  },
  {
    id: 'Troutman',
    label: 'Troutman',
    population: 11008,
    uuid: '4ac64962-8752-4a52-9c1f-264b4f703478',
  },
  {
    id: 'Wingate',
    label: 'Wingate',
    population: 10203,
    uuid: '10c79adc-c09f-43b2-9897-091ccea98798',
  },
  {
    id: 'Midland',
    label: 'Midland',
    population: 8966,
    uuid: 'b16a945f-aae5-40fd-8f85-63413ee9047e',
  },
  {
    id: 'Iron Station',
    label: 'Iron Station',
    population: 8513,
    uuid: '3168f0ee-a68e-4fdc-9833-308b320c8e5f',
  },
  {
    id: 'Mount Pleasant',
    label: 'Mount Pleasant',
    population: 8404,
    uuid: 'c80cd32b-da7e-46fd-ab2a-c709dda7ef6b',
  },
  {
    id: 'Cleveland',
    label: 'Cleveland',
    population: 7573,
    uuid: 'c25f1be9-1799-4e19-9b96-bdd7f3acfc57',
  },
  {
    id: 'Catawba',
    label: 'Catawba',
    population: 6887,
    uuid: '599980ba-a59d-4804-9e88-18219c26541f',
  },
  {
    id: 'Stony Point',
    label: 'Stony Point',
    population: 6440,
    uuid: 'ab8ae082-6261-4322-a7de-788bba512201',
  },
  {
    id: 'Sherrills Ford',
    label: 'Sherrills Ford',
    population: 6092,
    uuid: '47bbb808-e840-4183-bfb1-d520069cb2c5',
  },
  {
    id: 'Locust',
    label: 'Locust',
    population: 5269,
    uuid: '86ff50ba-758a-42cf-972b-b15cf1a3f992',
  },
  {
    id: 'Lowell',
    label: 'Lowell',
    population: 3960,
    uuid: 'db3dc71b-b647-44ec-85fd-b1205a644db9',
  },
  {
    id: 'Crouse',
    label: 'Crouse',
    population: 3322,
    uuid: 'a82270c2-070a-4741-b82e-7b66f74246dd',
  },
  {
    id: 'Cramerton',
    label: 'Cramerton',
    population: 3309,
    uuid: '5323f34b-3a14-4732-a035-4a8eb2692d57',
  },
  {
    id: 'Woodleaf',
    label: 'Woodleaf',
    population: 2413,
    uuid: '3ab5add4-f83e-4f1d-98b5-629c73dd1ec0',
  },
  {
    id: 'Alexis',
    label: 'Alexis',
    population: 1089,
    uuid: '3d029b59-4e4f-4210-ada1-f13420123c68',
  },
];

export const MARKET_NEIGHBORHOODS: Record<MarketLocation, NeighborhoodInfo[]> = {
  austin: austinNeighborhoods,
  houston: houstonNeighborhoods,
  dallas: dallasNeighborhoods,
  raleigh: raleighNeighborhoods,
  charlotte: charlotteNeighborhoods,
};

export const MARKET_NEIGHBORHOOD_IDS: Record<MarketLocation, string[]> = {
  austin: austinNeighborhoods.map(getId),
  houston: houstonNeighborhoods.map(getId),
  dallas: dallasNeighborhoods.map(getId),
  raleigh: raleighNeighborhoods.map(getId),
  charlotte: charlotteNeighborhoods.map(getId),
};
