import { ListingStates } from '../../components/exclusives/constants/constants';
import { Address } from './address';
import { ExclusiveListingInfo } from './exclusiveListingInfo';

export enum ListingPricingType {
  MLS,
  MLS_IN_SELLER_EXPERIMENT,
  FINAL,
  EXCLUSIVE,
}

export interface ListingFeatures {
  id: number | string;
  name: string;
  position?: number;
  value?: string;
}

export interface ListingPhoto {
  id?: number | string;
  url?: string;
  mime_type?: string;
  original_filename?: string;
  thumbnail_urls: {
    _375x250?: string;
    _768x512?: string;
    _1200x800?: string;
    _1800x1200?: string;
  };
}

export interface Listing {
  address: Address;
  bathrooms: number;
  bedrooms: number;
  close_date: string | null;
  dwelling_type: DwellingType | DwellingTypeHumanized;
  exclusive_listing_info: ExclusiveListingInfo;
  flip_state: FlipState | unknown;
  has_active_listing_offer?: boolean;
  id: number;
  listing_features: ListingFeatures[];
  listing_photos: ListingPhoto[];
  listing_price_cents?: number | null; // not included in basic serializer
  lonlat: [number, number];
  lot_square_footage: number;
  pending_date: string | null;
  pool_type?: PoolType[];
  price_cents: number; // listing price if listed, closed price if sold
  primary_features: [string, string | number][];
  remarks: string | null;
  school_district_elementary: string | null;
  school_district_high: string | null;
  slug: string;
  square_footage: number;
  timezone?: string;
  unenrolled_from_eo_program: boolean;
  year_built: number;
}

export interface SimilarListing
  extends Omit<
    Listing,
    | 'close_date'
    | 'listing_price_cents'
    | 'pending_date'
    | 'listing_features'
    | 'primary_features'
    | 'remarks'
    | 'listing_photos'
  > {
  fha_eligible_date?: string | null;
  display_state?: string;
  list_date?: string;
  parking_covered_count?: number;
  price_range?: string;
  upcoming_at?: string | null;
  view_count?: number;
  visit_count?: number;
  close_date?: string | null;
  listing_photos: Listing['listing_photos'] | null[];
}

export enum FlipState {
  PreListing = 'pre_listing',
  InRenovation = 'in_renovation',
  PreRenovation = 'pre_renovation',
  LateCheckout = 'late_checkout',
  ContigenciesReleased = 'contigencies_released',
  Listed = 'listed',
  ResaleInContract = 'resale_in_contract',
  ResaleContigenciesRelease = 'resale_contingencies_released',
}

export const FLIP_STATE_VALUES = Object.values(FlipState);

export const DwellingType = {
  Condo: 'condo',
  Gemini: 'gemini',
  Loft: 'loft',
  Mobile: 'mobile',
  Modular: 'modular',
  MultiFamily: 'multi-family',
  Patio: 'patio',
  SingleFamily: 'single-family',
  Townhouse: 'townhouse',
} as const;

export const DwellingTypeHumanized = {
  SingleFamily: 'single family home',
} as const;

export type DwellingTypeHumanized =
  (typeof DwellingTypeHumanized)[keyof typeof DwellingTypeHumanized];

export type DwellingType = (typeof DwellingType)[keyof typeof DwellingType];

export const PoolType = {
  Community: 'community',
  Private: 'private',
  PrivateSpa: 'private_spa',
  AboveGround: 'above_ground',
} as const;

export type PoolType = (typeof PoolType)[keyof typeof PoolType];

export interface ListingWithDaysRemaining extends Listing {
  days_remaining?: number;
}

export interface ListingWithListingStates extends Listing {
  el_state: ListingStates;
}

export type ListingWithComputedProperties = ListingWithListingStates & ListingWithDaysRemaining;

export type RecentlySoldHome = Pick<ListingWithComputedProperties, 'listing_price_cents'> & {
  city: Address['city'];
  state: Address['state'];
  photo_url: ListingPhoto['url'];
};
